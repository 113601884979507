import $ from 'jquery';
import { Component, Prop } from 'vue-property-decorator';
import StudioPageBase from '@/support/studioPageBase';
import Util from '@/support/utility';
import { IVideo } from '@/interfaces';

@Component
export default class VideoButtonsComponent extends StudioPageBase {
    options = {
    };

    show = false;
    id: string = null;
    parent: any = {};

    @Prop({ default: <any>{} })
    video: IVideo;

    @Prop({ default: <any>'' })
    mode: string;

    get hoverButtonsClass() {
        switch (this.mode) {
            case 'live':
            case 'live-mini':
                return '';
            case 'vod':
                return 'hover-buttons';
        }
        throw 'Unknown mode ' + this.mode;
    }

    created() {
        Debug.setDebugModule('videoButtons', this, Util.getRandomInt(1000)),
        this.parent = this.$parent;
        this.id = 'video-buttons-' + Util.getRandomInt();

        super.created('videoButtons', false);
    }

    mounted() {
        super.mounted();

        if (!this.serverData.isLimitedBrowser) {
            switch (this.mode) {
                case 'live':
                    this.show = true;
                    break;
                case 'live-mini':
                    this.show = true;
                    break;
                case 'vod':
                    this.show = this.studio.isAdmin;
                    $(this.$refs.hoverButtons).css("visibility", "collapse");
                    break;
            }
        }
    }

}
